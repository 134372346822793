import { Button } from "@inceptionbg/ui-components";
import { FC, useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ModeContext } from "../../../Pages/RPGPage";
import { getLandCultures } from "../../../repos/LandRepo";
import { ICulture, ILand } from "../../../types/ILand";
import { convertSurfaceToObj } from "../../../utils/CalculateUtils";
import { SubSection } from "../../SubSection";
import { LandForm } from "../Common/LandForm";
import { LandCultureForm } from "./LandCultureForm";
import { maxChar } from "../../../utils/StringUtils";
import { UserContext } from "../../../Context/UserContext";
import { FarmContext } from "../../../AppContent";

interface Props {
  land: ILand;
  reloadLands: (callback?: () => void) => void;
  reloadLandsSum?: () => void;
}

export const Land: FC<Props> = ({ land, reloadLands, reloadLandsSum }) => {
  const [cultures, setCultures] = useState<ICulture[]>([]);
  const [addCulture, setAddCulture] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const isRenew = useContext(ModeContext) === "RENEW";
  const { user } = useContext(UserContext);
  const { farm } = useContext(FarmContext);

  const surfaceObj = convertSurfaceToObj(land.usedSurface);

  const handleGetLandCultures = useCallback(
    (callback?: () => void) => {
      setIsLoading(true);
      getLandCultures(land.uuid)
        .then((e) => {
          setCultures(e.cultures);
          callback && callback();
        })
        .catch(() => {})
        .finally(() => setIsLoading(false));
    },
    [land.uuid]
  );

  return (
    <SubSection
      titleMulti={[
        `${land.cadastreNumber} - ${
          land.nickname
            ? maxChar(land.nickname, 20)
            : land.cadastreMunicipality.name
        }`,
        `${
          farm?.holder.type === "physicalPerson"
            ? land.member
              ? land.member.firstName + " " + land.member.lastName + " - "
              : user?.firstName + " " + user?.lastName + " - "
            : ""
        } ${t(land.usageBasis)}`,
        `${surfaceObj?.ha || 0}ha ${surfaceObj?.a || 0}a ${
          surfaceObj?.m || 0
        }m²`,
      ]}
      expandable
      onOpen={handleGetLandCultures}
      alerts={
        land.uuid
          ? [
              {
                text: t("RequiredFieldsMissing"),
                type: "error",
                hidden:
                  (land?.usageBasis !== "LEASE" || !!land?.leaseEndDate) &&
                  !!land?.surface &&
                  !!land?.usedSurface &&
                  (land?.usageBasis !== "LEASE" ||
                    !!land?.lessorIdentification) &&
                  !!land?.cadastreMunicipality &&
                  !!land?.cadastreNumber,
              },
              {
                text: t("RequiredFieldsMissingLandCultures"),
                type: "error",
                hidden: !cultures.find(
                  (e) => !e.plantCulture || !e.surface || !e.productionType
                ),
              },
              {
                text: `${t("WaitingComplementText")}
                          ${land.approval?.statusComment}`,
                type: "error",
                number: land.approval?.approvalNumber,
                hidden: land.approval?.approvalStatus !== "WAITING_COMPLEMENT",
              },
              {
                text: t("LandNotVerifiedInfo"),
                type: "info",
                number: land.approval?.approvalNumber,
                hidden:
                  !!land.approved ||
                  land.approval?.approvalStatus === "WAITING_COMPLEMENT",
              },
            ]
          : []
      }
    >
      <LandForm
        land={land}
        reloadLands={reloadLands}
        reloadLandsSum={reloadLandsSum}
        approvalUuid={land?.approval?.uuid}
      />
      {land.approval?.approvalStatus !== "WAITING_COMPLEMENT" && (
        <SubSection
          title={t("LandCulturesTitle")}
          secondary
          isLoading={isLoading}
        >
          <div className="form-container">
            {!!cultures?.length &&
              !isLoading &&
              cultures.map((culture) => {
                const cultureSurfaceObj = convertSurfaceToObj(culture.surface);
                return (
                  <SubSection
                    key={culture.uuid}
                    titleMulti={[
                      culture.plantCulture.name,
                      `${cultureSurfaceObj?.ha || 0}ha ${
                        cultureSurfaceObj?.a || 0
                      }a ${cultureSurfaceObj?.m || 0}m²`,
                    ]}
                    tertiary
                    expandable
                    alerts={[
                      {
                        text: t("RequiredFieldsMissing"),
                        type: "error",
                        hidden:
                          !!culture?.plantCulture &&
                          !!culture?.surface &&
                          !!culture?.productionType,
                      },
                    ]}
                  >
                    <LandCultureForm
                      landUuid={land.uuid}
                      culture={culture}
                      reloadLandCultures={handleGetLandCultures}
                      reloadLandsSum={reloadLandsSum}
                    />
                  </SubSection>
                );
              })}

            {addCulture && !isRenew && (
              <SubSection title={t("LandCultureAdd")} tertiary>
                <LandCultureForm
                  landUuid={land.uuid}
                  newForm={{ onCancel: () => setAddCulture(false) }}
                  reloadLandCultures={handleGetLandCultures}
                  reloadLandsSum={reloadLandsSum}
                />
              </SubSection>
            )}
          </div>
          {!isRenew && (
            <div className="flex justify-right mt-3">
              <Button
                label={t("LandCultureAdd")}
                primary
                size="s"
                onClick={() => setAddCulture(true)}
              />
            </div>
          )}
        </SubSection>
      )}
    </SubSection>
  );
};
