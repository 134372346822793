import { deleteEmptyProps, deleteProps } from "@inceptionbg/ui-components";
import { Dispatch, FC, SetStateAction, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { FarmContext } from "../../../../AppContent";
import { Dialog } from "../../../../Elements/Dialog";
import { addLand, isLandExist } from "../../../../repos/LandRepo";
import { IDialogTypeItem } from "../../../../types/IBase";
import { IEvidence } from "../../../../types/IFile";
import { ILand, ILandNew, ILandUsageBasis } from "../../../../types/ILand";
import {
  calculateSurfaceFromObj,
  convertSurfaceToObj,
} from "../../../../utils/CalculateUtils";
import { AddLandEvidence } from "./Components/AddLandEvidence";
import { AddLandForm } from "./Components/AddLandForm";
import { AddLandTypeList } from "./Components/AddLandTypeList";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  memberLand?: {
    memberUuid: string;
    memberName?: string;
    setLands?: Dispatch<SetStateAction<ILandNew[]>>;
  };
  land?: ILand;
  reloadLands: (callback?: () => void) => void;
}

type IStepType = "type" | "form" | "attachments";

export const AddLandDialog: FC<Props> = ({
  isOpen,
  onClose,
  memberLand,
  land,
  reloadLands,
}) => {
  const [step, setStep] = useState<IStepType>("type");
  const [type, setType] = useState("");
  const [formData, setFormData] = useState<ILandNew>({});
  const [evidences, setEvidences] = useState<IEvidence[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [disableSurfaceChange, setDisableSurfaceChange] = useState(false);
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const { t } = useTranslation();
  const farm = useContext(FarmContext).farm;
  const farmUuid = farm?.uuid;

  const isRegistrationWaitingComplement =
    farm?.registrationApproval?.approvalStatus === "WAITING_COMPLEMENT";

  const typeData: {
    [key in IStepType]: IDialogTypeItem;
  } = {
    type: {
      title: memberLand
        ? memberLand.memberName
          ? t("AddMemberLandTitleName", { name: memberLand.memberName })
          : t("AddMemberLandTitle")
        : t("AddLandTitle"),
      element: (
        <AddLandTypeList
          currentUB={land?.usageBasis}
          onSelect={(e) => {
            setType(e);
            setStep("form");
            if (land?.usageBasis === e) {
              const surfaceObj = convertSurfaceToObj(land.surface);
              const usedSurfaceObj = convertSurfaceToObj(land.usedSurface);
              setFormData({ ...land, surfaceObj, usedSurfaceObj });
            } else if (land?.approval) {
              setFormData({ approval: land.approval });
            }
          }}
        />
      ),
    },
    form: {
      title: memberLand?.memberName
        ? t(`AddMemberLandTitle${type}`, { name: memberLand.memberName })
        : t(`AddLandTitle${type}`),
      descEl: (
        <>
          <p>{t(`AddLandDesc${type}`)}</p>
          {type !== "CADASTRE_OWNERSHIP" && (
            <p className="bold mt-2">{t("AfterThatSubmitEvidence")}</p>
          )}
          {type === "OWNERSHIP_OUT_CADASTRE" && (
            <ul>
              <li>{t("OwnershipOutCadastreDocument")}</li>
            </ul>
          )}
          {["LEASE", "LEASE_LEGAL_ENTITY", "LEASE_CONSOLIDATION"].includes(
            type
          ) && (
            <ul>
              <li>{t("LeaseContract")}</li>
              <li>
                {t(
                  type === "LEASE_CONSOLIDATION"
                    ? "ConsolidationDocument"
                    : "CadastreDocument"
                )}
              </li>
              {type === "LEASE_LEGAL_ENTITY" && (
                <>
                  <li>{t("Attachment2a")}</li>
                  <a
                    href="https://erpg.eagrar.gov.rs/upload/Prilog2a-IZJAVA%20odgovornog%20lica%20u%20PL%20koje%20je%20vlasnik%20i%20zakupodavac%20odnosno%20ustupilac.pdf"
                    download
                    target="_blank"
                    rel="noreferrer"
                    className="link"
                  >
                    {t("PrintStatementForm")}
                  </a>
                </>
              )}
            </ul>
          )}
          {type === "CONSOLIDATION" && (
            <ul>
              <li>{t("ConsolidationDocument")}</li>
            </ul>
          )}
        </>
      ),
      element: (
        <AddLandForm
          type={type as ILandUsageBasis}
          formData={formData}
          setFormData={setFormData}
          memberUuid={memberLand?.memberUuid}
          setIsLoading={setIsLoading}
          disableSurfaceChange={disableSurfaceChange}
          setDisableSurfaceChange={setDisableSurfaceChange}
        />
      ),
      confirmBtn: {
        label: t(
          type === "CADASTRE_OWNERSHIP" || disableSurfaceChange
            ? "LandAdd"
            : "UploadAttachments"
        ),
        onFormSubmit: () =>
          type === "CADASTRE_OWNERSHIP" || disableSurfaceChange
            ? onSubmit()
            : setStep("attachments"),
        keepOpen: true,
        disabled: isLoading,
      },
      cancelBtn: {
        onClick: () => {
          setStep("type");
          setFormData({});
        },
      },
    },
    attachments: {
      title: t("LandEvidenceTitle"),
      desc: t("LandEvidenceDesc"),
      element: (
        <AddLandEvidence
          evidences={evidences}
          setEvidences={setEvidences}
          setIsLoading={setIsLoading}
        />
      ),
      confirmBtn: {
        label: t("LandAdd"),
        disabled: !evidences.length,
        onClick: () => {
          isLandExist(farmUuid!, {
            cadastreNumber: formData.cadastreNumber,
            cadastreMunicipalityUuid: formData.cadastreMunicipality?.uuid,
            usageBasis: type,
            surface: formData.surfaceObj,
            usedSurface: formData.usedSurfaceObj,
          }).then((data) => {
            data
              ? setIsOpenDialog(true)
              : onSubmit(evidences, () => setEvidences([]));
          });
        },
        keepOpen: true,
      },
      cancelBtn: { onClick: () => setStep("form") },
    },
  };

  const handleClose = () => {
    setStep("type");
    setType("");
    setFormData({});
    setEvidences([]);
    setIsLoading(false);
    onClose();
  };

  const onSubmit = (evidences?: IEvidence[], callback?: () => void) => {
    const memberUuid = memberLand?.memberUuid;
    const usageBasis = type;

    const data: ILandNew = deleteProps(
      deleteEmptyProps({
        ...formData,
        usageBasis,
        evidences,
        surface: formData.surfaceObj
          ? calculateSurfaceFromObj(formData.surfaceObj)
          : formData.surface,
        usedSurface: formData.usedSurfaceObj
          ? calculateSurfaceFromObj(formData.usedSurfaceObj)
          : formData.usedSurface,
        member: memberUuid ? { uuid: memberUuid } : undefined,
      }),
      ["surfaceObj", "usedSurfaceObj"]
    );

    if (isRegistrationWaitingComplement) {
      data.uuid = land?.uuid;
      data.approval = { uuid: farm?.registrationApproval?.uuid || "" };
    }

    if (!!memberLand?.setLands) {
      memberLand.setLands((e) => [...e, data as ILandNew]);
      handleClose();
    } else {
      setIsLoading(true);
      addLand(farmUuid!, data)
        .then(() => {
          reloadLands(() => {
            setIsLoading(false);
            callback && callback();
            handleClose();
          });
        })
        .catch(() => setIsLoading(false));
    }
  };

  return (
    <>
      <Dialog
        isOpen={isOpen}
        onClose={handleClose}
        title={typeData[step].title}
        desc={typeData[step].desc}
        descEl={typeData[step].descEl}
        confirmButton={typeData[step].confirmBtn}
        cancelButton={typeData[step].cancelBtn}
        size="m"
        isLoading={isLoading}
      >
        {typeData[step].element}
      </Dialog>
      <Dialog
        isOpen={isOpenDialog}
        onClose={() => setIsOpenDialog(false)}
        title={t("LandAdd")}
        desc={t("AddExistingLandDesc")}
        isLoading={isLoading}
        confirmButton={{
          label: t("AddExitingLandConfirm"),
          onClick: () => onSubmit(evidences, () => setEvidences([])),
        }}
        cancelButton={{
          label: t("Quit"),
          onClick: () => setIsOpenDialog(false),
        }}
      />
    </>
  );
};
