import { createContext, FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { BasicInfoSection } from "../Components/Sections/Basic/BasicInfoSection";
import { AnimalsSection } from "../Components/Sections/Animals/AnimalsSection";
import { LandSection } from "../Components/Sections/Land/LandSection";
import { MembersSection } from "../Components/Sections/Members/MembersSection";
import { RenewSection } from "../Components/Sections/Renew/RenewSection";
import { IPageMode } from "../types/IBase";
import { FarmContext } from "../AppContent";
import { BasicInfoSectionNew } from "../Components/Sections/Basic/BasicInfoSectionNew";
import { AuthorizedPersonsSection } from "../Components/Sections/AuthorizedPerson/AuthorizedPersonsSection";
import { Button } from "@inceptionbg/ui-components";
import {
  registerFarm,
  updateRegistrationApprovalStatus,
} from "../repos/FarmRepo";
import { Dialog } from "../Elements/Dialog";
import { Section } from "../Components/Section";
import { InfoBox } from "../Elements/Alert/InfoBox";
import { useNavigate } from "react-router-dom";
import { Loader } from "../Elements/Loader";

interface Props {
  mode: IPageMode;
}

export const ModeContext = createContext<IPageMode>("NEW");

export const RPGPage: FC<Props> = ({ mode }) => {
  const [landUpdated, setLandUpdated] = useState(false);
  const [confirmRegistrationOpen, setConfirmRegistrationOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { farm, reloadFarm } = useContext(FarmContext);
  const holderType = farm?.holder.type;
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <ModeContext.Provider value={mode}>
        {mode === "RENEW" && <RenewSection />}
        {mode === "NEW" && <BasicInfoSectionNew />}
        {mode === "EDIT" && (
          <>
            {farm?.registrationApproval?.approvalStatus ===
              "WAITING_COMPLEMENT" && (
              <Loader isLoading={isLoading}>
                <>
                  {farm.registrationApproval.statusComment && (
                    <InfoBox
                      title={farm.registrationApproval.statusComment}
                      className="mb-3"
                    />
                  )}
                  <div className="flex justify-right">
                    <Button
                      label={t("RequestComplementStatusChange")}
                      primary
                      size="l"
                      onClick={() => {
                        setIsLoading(true);
                        updateRegistrationApprovalStatus(
                          farm.registrationApproval!.uuid,
                          "COMPLEMENTED"
                        )
                          .then(() => {
                            reloadFarm();
                            navigate("/");
                          })
                          .catch(() => {})
                          .finally(() => setIsLoading(false));
                      }}
                    />
                  </div>
                </>
              </Loader>
            )}
            <BasicInfoSection />
            {holderType === "physicalPerson" && (
              <MembersSection
                reloadLands={() => setLandUpdated(!landUpdated)}
              />
            )}
            {holderType === "legalEntity" && <AuthorizedPersonsSection />}
            <LandSection
              landUpdated={landUpdated}
              activeMembersCount={farm?.activeMembersCount}
            />
            <AnimalsSection />
            {!["PENDING", "PENDING_APPROVAL"].includes(farm?.status || "") && (
              <RenewSection />
            )}
            {farm?.uuid && farm.status === "PENDING" && (
              <Section id="registration" title="">
                <div className="flex justify-right">
                  <Button
                    label={t("RequestFarmRegistration")}
                    primary
                    size="l"
                    onClick={() =>
                      registerFarm(farm?.uuid, {
                        status: "PENDING_APPROVAL",
                      })
                        .then(() => {
                          reloadFarm();
                          setConfirmRegistrationOpen(true);
                        })
                        .catch(() => {})
                    }
                  />
                </div>
              </Section>
            )}
          </>
        )}
      </ModeContext.Provider>
      <Dialog
        isOpen={confirmRegistrationOpen}
        onClose={() => setConfirmRegistrationOpen(false)}
        title={t("ConfirmRegistrationTitle")}
        desc={t("ConfirmRegistrationDesc")}
        confirmButton={{ okButton: true }}
      />
    </>
  );
};
