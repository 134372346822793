import { Button, deleteProps, TextInput } from "@inceptionbg/ui-components";
import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { FarmContext } from "../../../../AppContent";
import { IInputComponentProps, Input } from "../../../../Elements/Input";
import { updateHeadOffice } from "../../../../repos/FarmRepo";
import { IFarmNew, IHeadOfficeNew } from "../../../../types/IFarm";
import { SubSection } from "../../../SubSection";
import { Form } from "../../../Wrapper/FormWrapper";
import { getPlaceOptions } from "../../../../utils/LoadOptions";
import { SelectAsyncPaginate } from "../../../Inputs/SelectAsyncPaginate";
import { inputPattern } from "../../../../utils/InputPatternValidation";
import { EvidencesUploadDialog } from "../../../Files/EvidencesUploadDialog";
import { ModeContext } from "../../../../Pages/RPGPage";
import { IEvidence } from "../../../../types/IFile";
import { EvidencesComponent } from "../../../Files/EvidencesComponent";

interface Props {
  farmData?: IFarmNew;
  setFarm?: Dispatch<SetStateAction<IFarmNew>>;
}

export const HeadOffice: FC<Props> = ({ farmData, setFarm }) => {
  const [headOffice, setHeadOffice] = useState<IHeadOfficeNew>({});
  const [formData, setFormData] = useState<IHeadOfficeNew>({});
  const [evidences, setEvidences] = useState<IEvidence[]>([]);
  const [edit, setEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [attachmentsOpen, setAttachmentsOpen] = useState(false);
  const [attachments2Open, setAttachments2Open] = useState(false);

  const { t } = useTranslation();
  const { farm, reloadFarm } = useContext(FarmContext);
  const isRenew = useContext(ModeContext) === "RENEW";

  useEffect(() => {
    if (!setFarm && farm) {
      setHeadOffice(farm.headOffice || {});
      setFormData(farm.headOffice || {});
    }
  }, [farm, setFarm]);

  useEffect(() => {
    !!setFarm &&
      setFarm((e) => ({
        ...e,
        headOffice: {
          evidences: e.headOffice?.evidences,
          ...formData,
        },
      }));
  }, [setFarm, formData]);

  const holderInfo: IInputComponentProps[] = [
    {
      label: t("Street"),
      value: headOffice.street,
      input: (
        <TextInput
          required
          value={formData.street}
          setValue={(e) => setFormData({ ...formData, street: e })}
          error={!setFarm && !formData.street}
        />
      ),
      alert: {
        text: t("RequiredFieldError"),
        type: "error",
        active: !setFarm && !formData.street,
      },
    },
    {
      label: t("NumberLetter"),
      value: headOffice.houseNumber,
      input: (
        <TextInput
          required
          value={formData.houseNumber}
          setValue={(e) => setFormData({ ...formData, houseNumber: e })}
          error={!setFarm && !formData.houseNumber}
        />
      ),
      alert: {
        text: t("RequiredFieldError"),
        type: "error",
        active: !setFarm && !formData.houseNumber,
      },
    },
    {
      label: t("Place"),
      value: headOffice.place?.name,
      input: (
        <SelectAsyncPaginate
          required
          value={
            formData.place
              ? {
                  label: formData.place.name!,
                  value: formData.place.uuid!,
                }
              : null
          }
          loadOptions={getPlaceOptions}
          onChange={(e) =>
            setFormData(
              e
                ? {
                    ...formData,
                    place: {
                      name: e.label,
                      uuid: e.value,
                    },
                  }
                : deleteProps(formData, ["place"])
            )
          }
          error={!setFarm && !formData.place}
        />
      ),
      alert: {
        text: t("RequiredFieldError"),
        type: "error",
        active: !setFarm && !formData.place,
      },
    },
    {
      label: t("Municipality"),
      value: headOffice.municipality,
      input: (
        <TextInput
          required
          value={formData.municipality}
          setValue={(e) => setFormData({ ...formData, municipality: e })}
          error={!setFarm && !formData.municipality}
        />
      ),
      alert: {
        text: t("RequiredFieldError"),
        type: "error",
        active: !setFarm && !formData.municipality,
      },
    },
    {
      label: t("PostalCode"),
      value: headOffice.postalCode,
      input: (
        <TextInput
          required
          value={formData.postalCode}
          setValue={(e) => setFormData({ ...formData, postalCode: e })}
          inputProps={{ pattern: inputPattern.postalCode }}
          error={!setFarm && !formData.postalCode}
        />
      ),
      alert: {
        text: t("RequiredFieldError"),
        type: "error",
        active: !setFarm && !formData.postalCode,
      },
    },
  ];

  const onSubmit = () => {
    if (farm?.uuid) {
      const data = evidences.length ? { ...formData, evidences } : formData;
      setIsLoading(true);
      updateHeadOffice(farm.uuid, data)
        .then(() => {
          reloadFarm(() => {
            setIsLoading(false);
            setEdit(false);
          });
        })
        .catch(() => setIsLoading(false));
    }
  };

  return (
    <SubSection
      title={t("HeadOfficeTitleOrdered")}
      expandable={!setFarm}
      alerts={
        setFarm
          ? [
              {
                text: t("MissingAttachment"),
                type: "error",
                hidden: !!farmData?.headOffice?.evidences?.length,
              },
            ]
          : [
              {
                text: t("RequiredFieldsMissing"),
                type: "error",
                hidden:
                  !!farm?.headOffice.street &&
                  !!farm?.headOffice.houseNumber &&
                  !!farm?.headOffice.place &&
                  !!farm?.headOffice.municipality &&
                  !!farm?.headOffice.postalCode,
              },
              {
                text: `${t("WaitingComplementText")}
                    ${headOffice.approval?.statusComment}`,
                type: "error",
                number: headOffice.approval?.approvalNumber,
                hidden:
                  headOffice.approval?.approvalStatus !== "WAITING_COMPLEMENT",
              },
              {
                text: `${t("HeadOfficeNotVerifiedInfo")}`,
                type: "info",
                number: headOffice.approval?.approvalNumber,
                hidden:
                  !!headOffice.approved ||
                  headOffice.approval?.approvalStatus ===
                    "WAITING_COMPLEMENT" ||
                  headOffice.approval?.approvalStatus === "REJECTED",
              },
            ]
      }
    >
      {setFarm ? (
        <div className="form-container">
          {holderInfo.map(
            (e) => !e.hidden && <Input key={e.label} component={e} edit />
          )}
          <div className="flex justify-right">
            <Button
              label={t("UploadAttachments")}
              primary
              onClick={() => setAttachmentsOpen(true)}
              size="s"
            />
          </div>
        </div>
      ) : (
        <Form
          data={holderInfo}
          edit={edit}
          setEdit={setEdit}
          submitButton={{
            editLabel: t(edit ? "Confirm" : "HeadOfficeEdit"),
            onSubmit: () => setAttachments2Open(true),
          }}
          otherButtons={[
            {
              label: t("UploadAttachments"),
              onClick: () => setAttachmentsOpen(true),
              primary: true,
              hidden: isRenew || formData.approved || edit,
            },
          ]}
          onResetForm={() => setFormData(headOffice)}
          isLoading={isLoading}
        />
      )}
      {!isRenew && (
        <>
          <EvidencesUploadDialog
            isOpen={attachmentsOpen}
            onClose={() => {
              setAttachmentsOpen(false);
              if (formData.uuid) {
                reloadFarm(() => {
                  setIsLoading(false);
                  setEdit(false);
                });
              }
            }}
            category="HEAD_OFFICE"
            sectionEvidences={farmData?.headOffice?.evidences}
            setFarm={setFarm}
            approvalUuid={headOffice.approval?.uuid}
          />
          <EvidencesComponent
            isOpen={attachments2Open}
            onClose={() => setAttachments2Open(false)}
            category="HEAD_OFFICE"
            evidences={evidences}
            setEvidences={setEvidences}
            onSubmit={onSubmit}
          />
        </>
      )}
    </SubSection>
  );
};
