import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { FarmContext } from "../../../AppContent";
import { Input } from "../../../Elements/Input";
import { setPermanentHolder, updateHolder } from "../../../repos/FarmRepo";
import { IHolderNew } from "../../../types/IHolder";
import { Form } from "../../Wrapper/FormWrapper";
import { deleteMember, editMember } from "../../../repos/MembersRepo";
import { IFarmNew } from "../../../types/IFarm";
import { UserContext } from "../../../Context/UserContext";
import { getBirthDateFromPIN } from "../../../utils/CalculateUtils";
import { Dialog } from "../../../Elements/Dialog";
import { ModeContext } from "../../../Pages/RPGPage";
import { AddStatementDialog } from "../Members/Statement/AddStatementDialog";
import { getPersonalInfoData } from "./personalInfoData";
import { AddEditMemberDialog } from "../Members/Add/AddEditMemberDialog";

interface Props {
  member?: IHolderNew;
  isHolder?: boolean;
  setFarm?: Dispatch<SetStateAction<IFarmNew>>;
  reloadMembers?: (callback?: () => void) => void;
  isMember?: boolean;
  approvalUuid?: string;
}

export const PersonalInfoForm: FC<Props> = ({
  member,
  isHolder,
  setFarm,
  reloadMembers,
  isMember,
  approvalUuid,
}) => {
  const [formData, setFormData] = useState<IHolderNew>({});
  const [edit, setEdit] = useState(false);
  const [addStatement, setAddStatement] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [replaceMemberOpen, setReplaceMemberOpen] = useState(false);
  const [memberToDeleteUuid, setMemberToDeleteUuid] = useState("");
  const [displayPersonalIdentityNumber, setDisplayPersonalIdentityNumber] =
    useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation();
  const { farm, reloadFarm } = useContext(FarmContext);
  const { user } = useContext(UserContext);
  const isRenew = useContext(ModeContext) === "RENEW";

  const isWaitingComplement =
    member?.approval?.approvalStatus === "WAITING_COMPLEMENT" ||
    farm?.registrationApproval?.approvalStatus === "WAITING_COMPLEMENT";

  const resetFormData = useCallback(() => {
    if (!setFarm) {
      member && setFormData(member);
    } else if (isHolder) {
      setFormData((e) => ({
        ...e,
        firstName: user?.firstName || "",
        lastName: user?.lastName || "",
        personalIdentityNumber: user?.personalIdentityNumber || "",
        birthDate: getBirthDateFromPIN(user?.personalIdentityNumber) || "",
      }));
    }
  }, [member, isHolder, user, setFarm]);

  useEffect(resetFormData, [resetFormData]);

  useEffect(() => {
    !!setFarm && setFarm((e) => ({ ...e, holder: formData }));
  }, [setFarm, formData]);

  const memberInfo = getPersonalInfoData({
    formData,
    setFormData,
    t,
    edit,
    isNewForm: !!setFarm,
    isHolder,
    isMember,
    displayPIN: displayPersonalIdentityNumber,
    setDisplayPIN: setDisplayPersonalIdentityNumber,
  });

  const memberSubmitSuccess = () => {
    if (!!reloadMembers) {
      reloadMembers(() => {
        reloadFarm(() => {
          setIsLoading(false);
          setEdit(false);
        });
      });
    } else {
      reloadFarm(() => {
        setIsLoading(false);
        setEdit(false);
      });
    }
  };

  const onSubmit = () => {
    if (farm?.uuid) {
      setIsLoading(true);
      isHolder
        ? updateHolder(farm.uuid, formData)
            .then(() => {
              reloadFarm(() => {
                setIsLoading(false);
                setEdit(false);
              });
            })
            .catch(() => setIsLoading(false))
        : editMember(formData)
            .then(() => memberSubmitSuccess())
            .catch(() => setIsLoading(false));
    }
  };

  const onDelete = () => {
    if (memberToDeleteUuid) {
      setIsLoading(true);
      deleteMember(memberToDeleteUuid)
        .then(() => memberSubmitSuccess())
        .catch(() => setIsLoading(false));
    }
  };

  return setFarm ? (
    <div className="form-container">
      {memberInfo.map(
        (e) => !e.hidden && <Input key={e.label} component={e} edit />
      )}
    </div>
  ) : (
    <>
      <Form
        data={memberInfo}
        edit={edit}
        setEdit={setEdit}
        submitButton={{
          editLabel: t(
            edit ? "Confirm" : isHolder ? "ArgHolderEdit" : "MemberEdit"
          ),
          onSubmit,

        }}
        otherButtons={[
          {
            label: t("PermanentHolder"),
            primary: true,
            onClick: () => setIsOpen(true),
            hidden: isMember || !farm?.holder.temporary,
          },
          {
            label: t("DeleteMember"),
            onClick: () => {
              setMemberToDeleteUuid(formData.uuid || "");
            },
            hidden:
              (!(isMember && edit) &&
                farm?.registrationApproval?.approvalStatus !==
                  "WAITING_COMPLEMENT") ||
              isHolder,
            outlined: true,
            className: "error",
          },
          {
            label: t("AddMemberStatement"),
            onClick: () => setAddStatement(true),
            primary: true,
            hidden:
              isRenew ||
              isHolder ||
              !member ||
              member.approved ||
              edit ||
              isWaitingComplement,
          },
          {
            label: t("ReplaceMember"),
            primary: true,
            onClick: () => setReplaceMemberOpen(true),
            hidden: !isWaitingComplement || isHolder,
          },
        ]}
        onResetForm={resetFormData}
        isLoading={isLoading}
      />
      {member && !isHolder && (
        <AddStatementDialog
          isOpen={!!member.uuid && addStatement}
          onClose={() => {
            setAddStatement(false);
            if (member?.uuid) {
              reloadFarm(() => {
                setIsLoading(false);
                setEdit(false);
              });
            }
          }}
          memberUuid={member.uuid!}
          memberPIN={member.personalIdentityNumber!}
          category="MEMBER_STATEMENT"
          approvalUuid={approvalUuid}
        />
      )}
      <Dialog
        isOpen={!!memberToDeleteUuid}
        onClose={() => setMemberToDeleteUuid("")}
        title={t("DeleteMember")}
        desc={t("DeleteMemberDesc")}
        isLoading={isLoading}
        confirmButton={{ label: t("Confirm"), onClick: onDelete }}
        cancelButton={{}}
      />
      {isWaitingComplement && (
        <AddEditMemberDialog
          isOpen={replaceMemberOpen}
          onClose={() => setReplaceMemberOpen(false)}
          member={member}
          reloadMembers={reloadMembers}
        />
      )}
      <Dialog
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title={t("PermanentHolder")}
        desc={t("PermanentHolderDesc")}
        isLoading={isLoading}
        confirmButton={{
          label: t("Yes"),
          onClick: () =>
            setPermanentHolder({
              farm: { uuid: farm?.uuid! },
              permanent: true,
            }),
        }}
        cancelButton={{
          label: t("No"),
          onClick: () => setIsOpen(false),
        }}
      />
    </>
  );
};
